import React from "react";
import "./flat.css";
import { Carousel } from 'react-bootstrap'

class Flat extends React.Component {
  render() {
    // const title = this.props.flat.price + "€ - " + this.props.flat.description;
    const price = (""+this.props.flat.price).split("").splice(0,3).join("") + " " + (""+this.props.flat.price).split("").splice(3,6).join("") + " €"

    // const style ={
    //   // backgroundImage: "url('" + this.props.flat.imageUrl + "')"
    //   backgroundImage: "url('https://se-loger-scraping.s3-eu-west-1.amazonaws.com/" + this.props.flat.id + "0.jpg')"
    // };

    const backgroundImage0 = "url(https://se-loger-scraping.s3-eu-west-1.amazonaws.com/" + this.props.flat.id + "0.jpg)"
    console.log(backgroundImage0)
    const backgroundImage1 = "url(https://se-loger-scraping.s3-eu-west-1.amazonaws.com/" + this.props.flat.id + "1.jpg)"

    const price_by_square_meter = Math.trunc(this.props.flat.price / this.props.flat.surface)
    let price_by_square_meter_2 = ""
    if (price_by_square_meter.toString().length === 5) {
      price_by_square_meter_2 = (""+price_by_square_meter).split("").splice(0,2).join("") + " " + (""+price_by_square_meter).split("").splice(2,6).join("") + " €"
    } else {
      price_by_square_meter_2 = (""+price_by_square_meter).split("").splice(0,1).join("") + " " + (""+price_by_square_meter).split("").splice(1,6).join("") + " €"
    }
    // const style ={
    //   backgroundImage: `url('${this.props.flat.imageUrl}')`
    // };

    return (
      <div className="flat">
        <div className="flat-picture">
            <Carousel interval={null}>
              <Carousel.Item>
                <div
                  className="d-block w-100 h-100"
                  style={{backgroundImage: backgroundImage0}}
                  alt="First slide"
                />
                {/*<Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>*/}
              </Carousel.Item>

              <Carousel.Item>
                <div
                  className="d-block w-100 h-100"
                  style={{backgroundImage: backgroundImage1}}
                  alt="Second slide"
                />
                {/*<Carousel.Caption>
                  <h3>Second slide label</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>*/}
              </Carousel.Item>
            </Carousel>
        </div>
        {/*<div className="flat-type">{this.props.flat.type}</div>*/}
        <div className="flat-location">{price}&nbsp;&nbsp;({price_by_square_meter_2}/m<sup>2</sup>)</div>
        <div className="flat-location">{this.props.flat.commune} - {this.props.flat.quartier}</div>
        <div className="flat-details">
          {this.props.flat.surface ? <div className="flat-detail">{this.props.flat.surface} m<sup>2</sup></div>:null}
          {this.props.flat.piece ? <div className="flat-detail">{this.props.flat.piece} pièces</div>:null}
          {this.props.flat.chambre ? <div className="flat-detail">{this.props.flat.chambre} chambres</div>:null}
          {this.props.flat.etage ? <div className="flat-detail">{this.props.flat.etage}e étage</div>:null}
          {this.props.flat.ascenseur ? <div className="flat-detail">{this.props.flat.ascenseur}1 ascenseur</div>:null}
          {this.props.flat.terrasse ? <div className="flat-detail">{this.props.flat.terrasse}1 terrasse</div>:null}
        </div>
        <div className="flat-title">{this.props.flat.description}</div>
      </div>
    );
  }
}

export default Flat;
