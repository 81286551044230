import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import axios from 'axios'
// import { useHistory } from "react-router-dom";

const RegisterScreen = () => { // { location, history }
  // useEffect(() => {
  //   const { data } = await axios.get(`/api/products/${id}`)
  // }

  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // }

  // const { data } = await axios.post(
  //   '/api/users/login',
  //   { email, password },
  //   config
  // )

  // axios.post("https://se-loger-clone-back.herokuapp.com/users/login", { email: "jane@example.com", password: "123456" })
  //   .then(response =>{
  //   // this.albums = response.data
  //   console.log(response);
  //   })
  //   .catch(error =>{
  //   console.log(error);
  //   })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // const history = useHistory();
  const [error, setError] = useState('')

  // const dispatch = useDispatch()
  //
  // const userLogin = useSelector((state) => state.userLogin)
  // const { loading, error, userInfo } = userLogin
  //
  // const redirect = location.search ? location.search.split('=')[1] : '/'
  //
  // useEffect(() => {
  //   if (userInfo) {
  //     console.log('Check user info')
  //     history.push(redirect)
  //   }
  // }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()

    let url = ""

    if (process.env.NODE_ENV === 'development') {
      url = "http://localhost:8080/users/register";
    } else {
      url = "https://se-loger-clone-back.herokuapp.com/users/register";
    }

    let url_front = ""

    if (process.env.NODE_ENV === 'development') {
      url_front = "http://localhost:3000/";
    } else {
      url_front = "https://mes-annonces-immobilieres.fr/";
    }

    axios.post(url, { email: email, password: password})
      .then(response =>{
        window.analytics.track("Register", {
          email: email,
          password: password
        });
        localStorage.setItem('userInfo', JSON.stringify(response))
        console.log('Register');
        window.location.href = url_front;
      })
      .catch((error) =>{
        setError("Try again")
        // console.log('Not Register');
      })
    // dispatch(login(email, password))
    // console.log(email)
    // console.log(password)
  }
  return (
    <FormContainer>
      <h1>Créer un compte</h1>
      {error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <p> </p>
      )}
      {/*{error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}*/}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Adresse email</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Créer un compte
        </Button>
      </Form>
    </FormContainer>
  )
}

export default RegisterScreen
