import React, { useState, useEffect }  from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import PaymentScreen from './screens/PaymentScreen'
import RegisterScreen from './screens/RegisterScreen'
// import NotFound from './screens/NotFound'

const App = () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const userInfoSource = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
    setUserInfo(userInfoSource)
  },[]);

  return (
    <Router>
    <Header userInfo={userInfo}/>
    <main className='py-3'>
      <Container>
        <Switch>
          <Route path='/' component={HomeScreen} exact /> {/* // Exact because need to continue to /logout */}
          {userInfo ? (
            <Route path='/payment' component={PaymentScreen} />
          ) : (
            <Route path='/register' component={RegisterScreen} />
          )}
          {userInfo ? (
            <Route path='/logout' component={HomeScreen} />
          ) : (
            <Route path='/login' component={LoginScreen} />
          )}
          <Route component={HomeScreen} />
        </Switch>
      </Container>
    </main>
    </Router>
  );
}

export default App;
