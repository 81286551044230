import React from 'react'
import './Header.css';
// import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Container } from 'react-bootstrap'
// import { useHistory } from "react-router-dom";

let url = ""

if (process.env.NODE_ENV === 'development') {
  url = "http://localhost:3000/";
} else {
  url = "https://mes-annonces-immobilieres.fr/";
}

const url_login = url + "login"

const url_register = url + "register"

const url_payment = url + "payment"

const Header = (props) => {
    // const userInfoFromStorage = localStorage.getItem('userInfo')
    //   ? JSON.parse(localStorage.getItem('userInfo'))
    //   : null
    // console.log(userInfoFromStorage)

    // const history = useHistory();
    const logoutHandler = () => {
      // dispatch(logout())
      localStorage.removeItem('userInfo');
      // history.push("/logout");
    }

    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <Container>
                    {/*// <LinkContainer to='/'>
                    // <Navbar.Brand>Mes-Annonces-Immobilieres.com</Navbar.Brand>
                    // </LinkContainer>*/}
                    <div>
                    <Navbar.Brand><u><a href={url}>Mes-Annonces-Immobilieres.fr</a></u></Navbar.Brand>
                    </div>
                    {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <LinkContainer to='/login'>
                                <Nav.Link>
                                    <i className='fas fa-user'></i> Login
                                </Nav.Link>
                            </LinkContainer>
                            // {props.userInfo ? (
                            //     <NavDropdown title={props.userInfo.data.email} id='username'>
                            //     <LinkContainer to='/test'>
                            //         <NavDropdown.Item>Home</NavDropdown.Item>
                            //     </LinkContainer>
                            //     <NavDropdown.Item>
                            //     <LinkContainer to='/payment'>
                            //       <NavDropdown.Item>
                            //           <i class="fas fa-credit-card"></i> Payment
                            //       </NavDropdown.Item>
                            //     </LinkContainer>
                            //     </NavDropdown.Item>
                            //     </NavDropdown>
                            // ) : (
                            //   <LinkContainer to='/login'>
                            //   <Nav.Link>
                            //       <i className='fas fa-user'></i> Login
                            //   </Nav.Link>
                            //   </LinkContainer>
                            // )}
                        </Nav>
                    </Navbar.Collapse>*/}
                    {/*{props.userInfo ? (<Navbar.Brand>{props.userInfo.data.email}</Navbar.Brand>) : (<p></p>)}*/}
                    {props.userInfo ? (
                      props.userInfo.data.isPayingCustomer ? (
                        <div>
                        <Navbar.Brand>Paiement Effectué</Navbar.Brand>
                        <Navbar.Brand><u><a href={url} onClick={logoutHandler}>Déconnexion</a></u></Navbar.Brand>
                        </div>
                      ) : (
                        <div>
                        <Navbar.Brand><i className="fas fa-credit-card"></i> <u><a href={url_payment}>Paiement</a></u></Navbar.Brand>
                        <Navbar.Brand><u><a href={url} onClick={logoutHandler}>Déconnexion</a></u></Navbar.Brand>
                        </div>
                      )
                    ) : (
                      <div>
                      <Navbar.Brand><u><a href={url_register} onClick={logoutHandler}>Créer un compte</a></u></Navbar.Brand>
                      <Navbar.Brand><u><a href={url_login} onClick={logoutHandler}>Se connecter</a></u></Navbar.Brand>
                      </div>
                    )}
                    {/*{props.userInfo ? (<p></p>) : (
                        <LinkContainer to='/login'>
                        <Nav.Link>
                            <i className='fas fa-user'></i> Login
                        </Nav.Link>
                        </LinkContainer>
                    )}*/}
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
