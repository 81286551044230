import React, { useState, useEffect } from "react";
// import { LinkContainer } from 'react-router-bootstrap'
// import { Navbar } from 'react-bootstrap'
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const ProductDisplay = ({ handleClick }) => (
  <section>
    <div className="product">
      {/*// <img
      //   src="https://i.imgur.com/EHyR2nP.png"
      //   alt="The cover of Stubborn Attachments"
      // />*/}
      <div className="description">
        <p>Accédez à toutes les annonces de Mes-Annonces-Immobilieres.com pour 2€</p>
        {/*<h5>€2.00</h5>*/}
      </div>
    </div>
    <button type="button" id="checkout-button" role="link" onClick={handleClick}>
      Payez 2€
    </button>
  </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    let url_front = ""

    if (process.env.NODE_ENV === 'development') {
      url_front = "http://localhost:3000/";
    } else {
      url_front = "https://mes-annonces-immobilieres.fr/";
    }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    const userInfoSource = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

    if (query.get("ldmspe")) {
      setMessage("Paiement effectué. Merci pour votre achat.");
      if (process.env.NODE_ENV === 'development') {
          axios.post("http://localhost:8080/users/pay", { _id: userInfoSource.data._id})
            .then(response =>{
              console.log(response);
            })
            .catch(error =>{
              console.log(error)
            })
          userInfoSource.data.isPayingCustomer = true
          localStorage.setItem('userInfo', JSON.stringify(userInfoSource))
          window.location.href = url_front;
      } else {
          axios.post("https://se-loger-clone-back.herokuapp.com/users/pay", { _id: userInfoSource.data._id})
            .then(response =>{
              console.log(response);
            })
            .catch(error =>{
              console.log(error)
            })
          userInfoSource.data.isPayingCustomer = true
          localStorage.setItem('userInfo', JSON.stringify(userInfoSource))
          window.location.href = url_front;
      }
    }

    if (query.get("canceled")) {
      setMessage(
        "Paiement non effectué. N'hesitez pas à réessayer ulterieurement."
      );
    }
  }, []);

  let url = ""
  let myStripeKey = ""

  if (process.env.NODE_ENV === 'development') {
    url = "http://localhost:8080/create-checkout-session";
    myStripeKey = "pk_test_5joMtjhWuUhjFTekOsdXKSyp00X4miQ96I"
  } else {
    url = "https://se-loger-clone-back.herokuapp.com/create-checkout-session";
    myStripeKey = "pk_live_qSBY6yJqcwNsmnxvq6USwW7p00wspYz4la"
  }

  const handleClick = async (event) => {
    const stripePromise = loadStripe(myStripeKey);
    const stripe = await stripePromise;
    const response = await fetch(url, {
      method: "POST",
    });

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  // return <div></div>
  return message ? (
    <div>
    <Message message={message} />
    {/*// <LinkContainer to='/'>
    //     <Navbar.Brand>Back to Mes-Annonces-Immobilieres.com</Navbar.Brand>
    // </LinkContainer>*/}
    </div>
  ) : (
    <div>
    <ProductDisplay handleClick={handleClick} />
    {/*// <LinkContainer to='/'>
    //     <Navbar.Brand>Back to Mes-Annonces-Immobilieres.com</Navbar.Brand>
    // </LinkContainer>*/}
    </div>
  );
}
