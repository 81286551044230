import React, { useState, useEffect }  from 'react';
import './HomeScreen.css';
// import Header from '../components/Header'
// import { Button } from 'react-bootstrap'
import Flat from '../components/flat';
// import { Multiselect } from 'multiselect-react-dropdown';

let url = ""

if (process.env.NODE_ENV === 'development') {
  url = "http://localhost:3000/";
} else {
  url = "https://mes-annonces-immobilieres.fr/";
}

const url_login = url + "login"
const url_register = url + "register"
const url_payment = url + "payment"

let url_back = ""

if (process.env.NODE_ENV === 'development') {
  url_back = "http://localhost:8080/";
} else {
  url_back = "https://se-loger-clone-back.herokuapp.com/";
}

const HomeScreen = (props) => {
  const [flats, setFlats] = useState([]);
  // const [allFlats, setAllFlats] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [pages, setPages] = useState(10);
  const [pagination, setPagination] = useState(false);
  // const pages = 10;
  const [description, setDescription] = useState('');

  useEffect(() => {
    const userInfoSource = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

    setUserInfo(userInfoSource)
  },[]);

  useEffect(() => {
    let full_url = ""
    let dataa = null
    // console.log(userInfo)

    if (userInfo) {
        full_url = url_back + "feed/posts?items=" + pages + "&texte=" + description
        dataa = { token: userInfo.data.token}
        console.log(userInfo.data.token)
        console.log(userInfo.data.isPayingCustomer)
    } else {
        full_url = url_back + "feed/posts?texte=" + description
        dataa = {}
    }

    // console.log(userInfo)

    // const full_url = userInfoSource ? url_back + "feed/posts?items=" + pages + "&texte=" + description : url_back + "feed/posts?items=8&texte="

    console.log(full_url)

    fetch(full_url, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(dataa) })
      .then(response => {return response.json();})
      // .then(response => response.json()) // Why it does not work ?
      .then(response => response.data)
      // // .then(data => data.concat(data))
      .then((data) => {
        if (data.length % 10 !== 0)
        {
          console.log('end')
          setPagination(true)
          setFlats(data)
        } else {
          console.log('start')
          setPagination(false)
          setFlats(data)
          // setAllFlats(data)
        }
      })
  },[description, pages, userInfo]);

  const handleSearch = (event) => {
    setDescription(event.target.value)
    console.log(description)
  }

  const button = <input type="text" placeholder=" Votre recherche" onChange={handleSearch} />;

  const loadHandler = () => {
    const c = pages + 10
    console.log(c)
    setPages(c)
    window.analytics.track("Load", {
      page: c
    });
    const userInfoSource = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
    setUserInfo(userInfoSource)
  }

  return (
    <div>
      <div className="intro">Retrouvez toutes les annonces immobilières passées sur le web ces derniers mois</div>
      {/*<Header userInfo={userInfo}/>*/}
      <div className="app">
      <div className="main">
        <div className="search">
          {button}
        </div>
        {/*<Multiselect
          options={this.state.options} // Options to display in the dropdown
          // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
          onSelect={this.onSelect} // Function will trigger on select event
          onRemove={this.onRemove} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />*/}
        <div className="flats">
          {flats.map((flat) => {
            return <Flat key={flat.id} flat={flat}/>
          })}
        </div>
        {userInfo ? (
          (pagination === false) ? (
            userInfo.data.isPayingCustomer || flats.length < 50 ? (
              <div className="login-for-more"><a href="#bottom" onClick={loadHandler}> Voir davantage d'annonces</a></div>
            ) : <div className="login-for-more"><u><a href={url_payment}>Payez pour voir toutes les annonces</a></u></div>
          ) : <div className="login-for-more">Tous les annonces sont affichées</div>
        ) : <div className="login-for-more"><u><a href={url_register}>Créez un compte</a></u> ou <u><a href={url_login}>connectez-vous</a></u> pour voir davantage d'annonces</div>}
      <div className="contact">Pour toute demande: contact@mes-annonces-immobilieres.fr</div>
      </div>
      </div>
    </div>
  )
}

export default HomeScreen;
